<template>
  <div class="page-class page-setting-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <table-box
      :columns="columns"
      :rows="commentsList"
      :baseUrl="baseUrl"
      :loading="loading"
      emptyText="No Comments found."
      :showAdd="false"
      :showToolbar="false"
      :steps="steps"
      :callbacks="callbacks"
      @open="onView"
    >
    </table-box>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TableBox from '@/components/TableBox.vue';
import router from '@/router';

export default {
  name: 'CommentList',
  components: {
    TableBox
  },
  metaInfo() {
    return {
      title: 'Comments',
      meta: [
        {
          name: 'description',
          content: 'View Comments'
        }
      ]
    };
  },
  mounted() {
    // if (window.localStorage.getItem('hasCompletedCommentlist') !== 'true') {
    //   this.$tours.myTour.start();
    // }
    this.listComment({ router });
  },
  data() {
    return {
      title: 'Comments',
      callbacks: {
        onFinish() {
          window.localStorage.setItem('hasCompletedCommentlist', 'true');
        }
      },
      steps: [
        {
          target: '[data-v-step="10"]', // We're using document.querySelector() under the hood
          content: `List of Latest Comments as per Document Type .`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="6"]',
          content: 'Click on the Comment to view the details.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        }
      ],
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'rowNum',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Comment',
          class: { 'text-capitalize': true },
          textKey: 'body',
          width: '50%'
        },
        {
          type: 'string',
          class: { 'text-capitalize': true },
          headerText: 'Document Type',
          textKey: 'docType',
          width: '14%'
        },
        {
          type: 'string',
          headerText: 'Updated At',
          textKey: 'updatedAt',
          width: '17%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '12%',
          functions: {
            open: true,
            edit: false
          }
        }
      ]
    };
  },
  computed: {
    ...mapState('comment', ['loading', 'baseUrl', 'commentsList'])
  },
  methods: {
    ...mapActions('comment', ['listComment']),

    onView({ row }) {
      if (row.docType === 'event') {
        row.docType = 'photo-sharing-event';
      }
      router.push(`/${row.docType}/${row.id}`);
    }
  }
};
</script>
